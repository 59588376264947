import axios from 'axios';

export async function sendDecline(issueKey: string | undefined, user: string | null, declineReason: string | null): Promise<any> {
    const url = 'https://api-jira.azurewebsites.net/api/declineChange?code=_mcVfxbrT1zgpbXqxAB5EgnuRi8wCbU5J-kivynFzLgnAzFuJrwrvg==';
    const data = { issueKey: issueKey, user: user, declineReason: declineReason};
    const headers = {
        'Authorization': 'Bearer An3wToken123JSldot94HSncdTWOEY042',
        'Accept': 'application/json'
    };
    
    try {
        const response = await axios.post(url, data, { headers });
        return response.data;
    } catch (error) {
        throw error;
    }
}