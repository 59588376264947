import axios from 'axios';

export async function getissueDetails(issueKey: string): Promise<any> {
    const url = 'https://api-jira.azurewebsites.net/api/getIssueDetails?code=Pa43f9UhjfWm37vtpj2Yn70wFCrzWaGSTwmKJ0iwx51lAzFuPCb6ZA==';
    const data = { issueKey: issueKey };
    const headers = {
        'Authorization': 'Bearer An3wToken123JSldot94HSncdTWOEY042',
        'Accept': 'application/json'
    };
    
    try {
        const response = await axios.post(url, data, { headers });
        console.log('Response:', response.data)
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}