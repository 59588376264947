import React from 'react';
import { useParams } from 'react-router-dom';

const NotChange: React.FC = () => {
    const { issueKey } = useParams<{ issueKey: string }>();

    return (
        <div className="main" style={{"height": "100vh", "width": "100vw", "overflow": "hidden", "display": "grid", 'alignItems': 'center', 'justifyContent': 'center'}}>
            <div style={{'textAlign': 'center', 'height': 'auto', 'width': 'auto', 'transform': 'translateY(-50%)'}}>
                <h2>The issue with ID {issueKey} is not a change ticket!</h2>
                <p>Please exit the browser and inform the IT team</p>
                <iframe className="pdf" src="https://anewclimate.atlassian.net/rest/api/3/attachment/25895?height=1000&width=1000">
                </iframe>
            </div>
        </div>
    );
};

export default NotChange;