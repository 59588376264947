const customFields = {
    "Approver Groups": "customfield_10175",
    "Affected Services": "customfield_10176",
    "Change Type": "customfield_10005",
    "Impact": "customfield_10004",
    "Urgency": "customfield_10032",
    "Change Risk": "customfield_10006",
    "Change Reason": "customfield_10007",
    "Implementation Plan": "customfield_10041",
    "Backout Plan": "customfield_10042",
    "Test Plan": "customfield_10043",
    "Planned Start": "customfield_10044",
    "Planned End": "customfield_10045"
};

export default customFields;