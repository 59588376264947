interface ADFNode {
    type: string;
    content?: ADFNode[]; // Optional because not all nodes will have content (e.g., text nodes)
}

interface ParagraphNode extends ADFNode {
    content: ADFNode[]; // Overrides to make content non-optional for paragraph nodes
}

interface TextNode extends ADFNode {
    text: string; // Ensures text property must exist for text nodes
}

function ADFExtract(adfData: ADFNode): string {
    let textContent = '';
    if (!adfData) {
        return 'Not provided';
    }

    function processNode(node: ADFNode) {
        if (node.type === 'paragraph' && (node as ParagraphNode).content) {
            // Using a type assertion to treat node as ParagraphNode
            (node as ParagraphNode).content.forEach(subNode => {
                processNode(subNode);  // Recursive call for sub-nodes
                textContent += '\n'; // Add a newline after each sub-node
            });
        }
        else if (node.type === 'text') {
            // Using a type assertion to treat node as TextNode
            textContent += (node as TextNode).text;
        }
    }

    if (adfData.type === 'doc' && adfData.content) {
        adfData.content.forEach(node => {
            processNode(node);
        });
    }

    return textContent.trim(); // Trim the final string to remove any extraneous newline at the start or end
}

export default ADFExtract;