import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getissueDetails } from '../services/getIssueDetails';
import customFields from '../Utils/customFileds'; import ADFExtract from '../Utils/ADFExtract'; import extractDate from '../Utils/stripDate'; import { sendApproval } from '../services/sendApproval'; import { sendDecline } from '../services/sendDecline';
import '../styles/IssuePage.css'
import '../styles/loadingSpinner.css';

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  const currentUser = params.get('user');
  return currentUser
}

const IssuePage = () => {
  const { issueKey } = useParams();
  const [currentUser, setCurrentUser] = useState<string | null>(null);
  const [issue, setIssue] = useState<any>(null);
  const [reason, setReason] = useState('');
  const [issueLoading, setIssueLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [issueDetails, setIssueDetails] = useState({
    "Description": "",
    "Approver Groups": "",
    "Affected Services": "",
    "Change Type": "",
    "Impact": "",
    "Urgency": "",
    "Change Risk": "",
    "Change Reason": "",
    "Implementation Plan": "",
    "Backout Plan": "",
    "Test Plan": "",
    "Planned Start": "",
    "Planned End": ""
  })

  useEffect(() => {
    const fetchIssueDetails = async () => {
      try {
        const issueDetails = await getissueDetails(issueKey || '');
        setIssue(issueDetails);
      } catch (error) {
        console.error('Failed to fetch issue details:', error);
      }
    };
    const currentUser = getQueryParams();

    const fetchData = async () => {
      await fetchIssueDetails();
      setCurrentUser(currentUser);
    };

    fetchData();
    setIssueLoading(true);
  }, [issueKey]);

  useEffect(() => {
    if (issue && issue['data'] && issue['data']['fields']['issuetype']['name'] !== '[System] Change') {
      window.location.href = `/${issueKey}/NotChange`;
    }
    else if (issueLoading) {
      const approverGroup : any = issue['data']['fields'][`${customFields['Approver Groups']}`]?.map((item: any) => ({ value: item.value }));
      setIssueDetails(prevIssueDetails => ({
        ...prevIssueDetails,
        "Description": ADFExtract(issue['data']['fields']['description']),
        "Approver Groups": approverGroup.map((item: any) => item.value).join(', ').toString(),
        "Affected Services": issue['data']['fields'][`${customFields['Affected Services']}`]['value'],
        "Change Type": issue['data']['fields'][`${customFields['Change Type']}`]['value'],
        "Impact": issue['data']['fields'][`${customFields['Impact']}`]['value'],
        "Urgency": issue['data']['fields'][`${customFields['Urgency']}`]['value'],
        "Change Risk": issue['data']['fields'][`${customFields['Change Risk']}`]['value'],
        "Change Reason": issue['data']['fields'][`${customFields['Change Reason']}`]['value'],
        "Implementation Plan": ADFExtract(issue['data']['fields'][`${customFields['Implementation Plan']}`]),
        "Backout Plan": ADFExtract(issue['data']['fields'][`${customFields['Backout Plan']}`]),
        "Test Plan": ADFExtract(issue['data']['fields'][`${customFields['Test Plan']}`]),
        "Planned Start": extractDate(issue['data']['fields'][`${customFields['Planned Start']}`]),
        "Planned End": extractDate(issue['data']['fields'][`${customFields['Planned End']}`])
      }))
    } 
  }, [issue]);

  const hasMinimumWordCount = (text: string, minWords: number) => {
    const words = text.match(/\b\w+\b/g); // This regex matches whole words
    return words && words.length >= minWords;
  };
  

  const handleApproval = async () => {
    setApprovalLoading(true);
    try {
      const res = await sendApproval(issueKey, currentUser);
      if (res && res.message === 'Change successfully approved') {
        window.location.href = '/ThankYou';
      }
    }
    catch (error) {
      console.error('Failed to send approval', error);
    }
    setApprovalLoading(false);
  };

  const handleDecline = async () => {
    setDeclineLoading(true);
    try {
      const res = await sendDecline(issueKey, currentUser, reason);
      if (res && res.message === 'Change successfully declined') {
        window.location.href = '/ThankYou';
      }
    }
    catch (error) {
      console.error('Failed to send decline', error);
    }
    setDeclineLoading(false);
  };

  if (!issue) return (
    <div className='loadingBlock'>
      <div className="screenLoader"></div>
      <h3>Loading Change Ticket...</h3>
    </div>
  );

  return (
    <div className="parentBlock">
      <div className="container">
        <div className="header">
          <h2 style={{"margin": "3px"}}>{issue['data']['fields']['summary']}</h2>
          <h4 style={{"width": "100%", "textAlign": "center", "margin": "3px"}}>{issue['data']['key']}</h4>
          <textarea placeholder={issueDetails['Description']} disabled={true} />
        </div>
        <div className="issueBlock">
          <div className="issueDetail">
            <h2>Planned Start Date:</h2>
            <textarea placeholder={issueDetails['Planned Start']} disabled={true} />
          </div>
          <div className="issueDetail">
            <h2>Planned End Date:</h2>
            <textarea placeholder={issueDetails['Planned End']} disabled={true} />
          </div>
        </div>
        <div className="issueBlock">
          <div className="issueDetail">
            <h2>Affected Services:</h2>
            <textarea placeholder={issueDetails['Affected Services']} disabled={true} />
          </div>
          <div className="issueDetail">
            <h2>Approver Groups</h2>
            <textarea placeholder={issueDetails['Approver Groups']} disabled={true} />
          </div>
        </div>
        <div className="issueBlock">
          <div className="issueDetail">
            <h2>Change Type:</h2>
            <textarea placeholder={issueDetails['Change Type']} disabled={true} />
          </div>
          <div className="issueDetail">
            <h2>Impact:</h2>
            <textarea placeholder={issueDetails['Impact']} disabled={true} />
          </div>
        </div>
        <div className="issueBlock">
          <div className="issueDetail">
            <h2>Urgency:</h2>
            <textarea placeholder={issueDetails['Urgency']} disabled={true} />
          </div>
          <div className="issueDetail">
            <h2>Change Risk:</h2>
            <textarea placeholder={issueDetails['Change Risk']} disabled={true} />
          </div>
        </div>
        <div className="issueBlock">
          <div className="issueDetail">
            <h2>Change Reason:</h2>
            <textarea placeholder={issueDetails['Change Reason']} disabled={true} />
          </div>
          <div className="issueDetail">
            <h2>Implementation Plan:</h2>
            <textarea placeholder={issueDetails['Implementation Plan']} disabled={true} />
          </div>
        </div>
        <div className="issueBlock">
          <div className="issueDetail">
            <h2>Backout Plan:</h2>
            <textarea placeholder={issueDetails['Backout Plan']} disabled={true} />
          </div>
          <div className="issueDetail">
            <h2>Test Plan:</h2>
            <textarea placeholder={issueDetails['Test Plan']} disabled={true} />
          </div>
        </div>
        <div className='approveBlock'>
          <button onClick={handleApproval} disabled={approvalLoading}>{approvalLoading ? <div className="buttonLoader"></div> : 'Approve'}</button>
        </div>
        <div className={!hasMinimumWordCount(reason, 8) ? "declineBlockGrayed" : "declineBlock"}>
          <div className="reason">
            <textarea
              placeholder="Decline Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <button onClick={handleDecline} disabled={!hasMinimumWordCount(reason, 3) || declineLoading}>{declineLoading ? <div className="buttonLoader"></div> : 'Decline'}</button>
        </div>
      </div>
    </div>
  );
};

export default IssuePage;
