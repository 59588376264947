import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import IssuePage from './components/IssuePage';
import ThankYou from './components/ThankYou';
import NotChange from './components/NotChange';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/:issueKey" element={<IssuePage />} />
          <Route path="/ThankYou" element={<ThankYou />} />
          <Route path="/:issueKey/NotChange" element={<NotChange />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
