import axios from 'axios';

export async function sendApproval(issueKey: string | undefined, user: string | null): Promise<any> {
    const url = 'https://api-jira.azurewebsites.net/api/approveChange?code=CuAIWdvjhRw2xju45fafKpR7a6Ubx6XjHh6Qx5shIUJlAzFukjDQZw==';
    console.log(user)
    const data = { issueKey: issueKey, user: user};
    const headers = {
        'Authorization': 'Bearer An3wToken123JSldot94HSncdTWOEY042',
        'Accept': 'application/json'
    };
    
    try {
        const response = await axios.post(url, data, { headers });
        console.log('Send approval res:', response.data)
        return response.data;
    } catch (error) {
        throw error;
    }
}