import React from 'react';

const ThankYou: React.FC = () => {
    return (
        <div style={{'textAlign': 'center', 'justifyContent': 'center'}}>
            <h1>Thank You!</h1>
            <p>Your submission has been received.</p>
            <p>You may now exit the browser.</p>
        </div>
    );
};

export default ThankYou;